import { FC, readFile } from '@Common/Services'
import moment from 'moment'
import _ from 'lodash'

import state from '../State'
import { showGrowl, comparatorJSON } from './Common'
import { invalidateQuery } from '../Services'

const uploadFileOnAWS = async ({ _id, refFile }, tourId, tourStatus) => {
  let noNotify = false
  if (tourStatus === 1) noNotify = true
  if (!refFile) return _id

  try {
    if (refFile.size > 4000 * 1024) throw new Error('File too big')
    const uri = await readFile(refFile)
    const uploadedFile = await FC.service('upload').create({ uri }, { query: { entity: 'tours', id: tourId } })
    const patchPayload = {
      file: refFile.name,
      format: refFile.name?.split('.')[1] || 'pdf',
      from: 'BACKEND_APPTOUR',
      status: 1,
      title: refFile?.name,
      timestamp: refFile?.lastModified,
      size: refFile?.size,
      id: uploadedFile.id,
      type: 'EXTRA',
      noNotify
    }
    await FC.service('documents').patch(uploadedFile.documentId, patchPayload)
    return uploadedFile.documentId
  } catch (e) {
    window.growl.show({
      severity: 'error',
      summary: 'Errore file inserita',
      detail: e.message === 'File too big' ? 'File troppo grande ' : 'Errore caricamento file, solo PDF'
    })
    return null
  }
}

const getUpdatedLogs = (user, action, pastLogs) => {
  const log = {
    user: user._id,
    lastUpdate: moment(),
    operator: `${user.name} ${user.lastName}`,
    action
  }
  const logs = pastLogs ? Array.isArray(pastLogs) ? [log, ...pastLogs] : [log, pastLogs] : [log]
  return logs
}

const createTourObject = (status) => {
  const { startDate, endDate, tripDays, tripNights, description, note, _id, logs: pastLogs } = state.tourData.state
  const { copied, financeData } = state.tourActions.state
  const passengers = [{ ...state.clientData.state }, ...state.passengers.state]

  const money = state.moneyForm.state.length
    ? !state.moneyForm.state[0].id && !state.moneyForm.state[0].payed && !state.moneyForm.state[0].date && !state.moneyForm.state[0].total
        ? []
        : state.moneyForm.state
    : []

  const tour = {
    type: 'APP',
    passengers,
    startDate,
    endDate,
    tripDays,
    tripNights,
    description,
    note,
    hotels: state.hotelForm.state,
    flights: state.flightForm.state,
    trains: state.trainForm.state,
    transfers: state.carForm.state,
    services: state.serviceForm.state,
    cruises: state.cruiseForm.state,
    money,
    status: status === 0 ? 0 : 1,
    creationDate: moment(),
    showFinanceData: financeData ? 1 : 0
  }

  return { tour, _id, pastLogs, copied }
}

const uploadFiles = async (tourStatus, tourId) => {
  const mapFunctionUpload = (status, tourId) => async (document, index) => {
    if (!document?.needUpload) return { ...document, order: index }
    const documentId = await uploadFileOnAWS(document, tourId, status)
    return { ...document, _id: documentId, needUpload: false, order: index }
  }
  return Promise.all(state.toursDocuments.state.map(mapFunctionUpload(tourStatus, tourId)))
}

const modifyTour = async (tour, _id, status, pastLogs, user) => {
  const tourDocuments = await uploadFiles(tour.status, _id)
  const documentsOrder = tourDocuments.filter((doc) => doc).sort((d1, d2) => d1.order - d2.order).map((doc) => doc._id)

  tour.documentsOrder = [...documentsOrder]

  const areEquals = await comparatorJSON(_id, tour)
  if (!areEquals) {
    const updatedLogs = getUpdatedLogs(user, status ? 'Ha sincronizzato il viaggio' : 'Ha modificato il viaggio', pastLogs)
    await FC.client.service('tours').patch(_id, { ...tour, logs: [...updatedLogs] })
  }

  state.tourActions.setState({ ...state.tourActions.state, saved: true })
  state.toursDocuments.setState(tourDocuments)
  showGrowl('success', 'Modifica viaggio', 'Viaggio modificato correttamente')
  return _id
}

const createTour = async ({ _id, ...tour }, status, user) => {
  const updatedLogs = getUpdatedLogs(user, 'Ha creato il viaggio', tour.logs)
  tour.logs = [...updatedLogs]

  const createdTour = await FC.service('tours').create(tour)
  const tourDocuments = await uploadFiles(tour.status, createdTour._id)
  const documentsOrder = tourDocuments.filter((doc) => doc).sort((d1, d2) => d1.order - d2.order).map((doc) => doc._id)
  await FC.service('tours').patch(createdTour._id, { documentsOrder })

  state.toursDocuments.setState(tourDocuments)
  state.tourData.setState({ ...createdTour, tourResolver: createdTour?.resolver })
  state.tourActions.setState({ ...state.tourActions.state, saved: true, copied: false })

  showGrowl('success', status === 0 ? 'Bozza viaggio' : 'Creazione viaggio', status === 0 ? 'Bozza salvata correttamente' : 'Viaggio creato correttamente!')
  return createdTour._id
}

const checkPublishErrors = () => {
  const tourData = { ...state.tourData.state }
  return !tourData.tripDays || !tourData.tripNights || !tourData.endDate || !tourData.startDate || !tourData.description
}

const checkSaveErrors = (status) => {
  const isEmptyClientData = (value) => _.isEmpty(value) || (!value?.phone && !value?.surname && !value?.name && !value?.email)
  const isEmptyTourData = (value) => _.isEmpty(value) || !value?.startDate || !value?.endDate || !value?.description

  return isEmptyTourData(state.tourData.state) || isEmptyClientData(state.clientData.state) || (status && checkPublishErrors())
}

const elaborateError = () => {
  showGrowl('error', 'Errore durante il salvataggio del viaggio', 'Completare i dati obbligatori: CLIENTE e DATI PRATICA')
  state.tourActions.setState({ ...state.tourActions.state, saved: false })
  state.tourData.setState({ ...state.tourData.state, status: 0 })
  return false
}

export const saveFunction = async (user, status) => {
  if (checkSaveErrors(status)) return elaborateError()

  const { tour, _id, pastLogs, copied } = createTourObject(status)

  let id = ''
  if (!copied && _id) id = await modifyTour(tour, _id, status, pastLogs, user)
  else id = await createTour(tour, status, user)

  state.startCheckErrorsGlobal.setState(false)
  invalidateQuery(['tours', 'tour'])
  return id
}
