import React, { useState } from 'react'
import moment from 'moment'
import { Input, Button, NumberPrefix, Icon, FadeIn } from '@Common/Components'
import state from '../../../../State'
import { useWindowSize } from 'react-use'

const ICONS = [
  { label: 'ticket', value: 'ticket' },
  { label: 'bike', value: 'bike' },
  { label: 'bus', value: 'bus' },
  { label: 'escursioni', value: 'escursioni' },
  { label: 'guida-turistica', value: 'guida-turistica' },
  { label: 'museo', value: 'museo' },
  { label: 'experiments', value: 'experiments' },
  { label: 'extreme', value: 'extreme' },
  { label: 'image', value: 'image' },
  { label: 'motorbike', value: 'motorbike' },
  { label: 'teatre', value: 'teatre' },
  { label: 'beach', value: 'beach' },
  { label: 'cart', value: 'cart' },
  { label: 'culture', value: 'culture' },
  { label: 'gourmet', value: 'gourmet' },
  { label: 'mountain', value: 'mountain' },
  { label: 'travel', value: 'travel' },
  { label: 'binoculars', value: 'binoculars' },
  { label: 'camp', value: 'camp' },
  { label: 'cocktail', value: 'cocktail' },
  { label: 'family', value: 'family' },
  { label: 'camper', value: 'camper' },
  { label: 'dog', value: 'dog' },
  { label: 'tent', value: 'tent' }
]

const iconTemplate = (icon) => (
  <Icon key={'ico' + 1} name={icon?.value || 'ticket'} size={24} />
)
export default function Other ({ item = {}, onDelete, index, onUpdate }) {
  const updateItem = (update) => {
    const { _id = '' } = item
    onUpdate('serviceForm', { _id, ...update })
  }

  const changeSelectedCountry = (data) => {
    setPrefixCountry(data)
    const { _id = '' } = item
    onUpdate('serviceForm', {
      _id,
      prefixCountry: { label: data.label, prefix: data.prefix }
    })
  }

  const { main, ever } = styles
  const config = state.config.state
  const { width: screenWidth } = useWindowSize()

  const [prefixCountry, setPrefixCountry] = useState({ label: 'Italy (Italia)', prefix: '39', value: 104, priority: 0 })

  const renderContent = () => (
    <>
      <div style={main}>
        <div style={{ ...ever, flexDirection: 'column' }}>
          <div
            style={{
              ...ever,
              flexDirection: screenWidth > config.smallScreen + 150 ? 'row' : 'column'
            }}
          >
            <Input
              date
              id='startDate'
              label='Data inizio'
              style={{ width: '100%', marginTop: 5, marginRight: 5 }}
              onChange={(e) => updateItem(e)}
              value={item.startDate ? moment(item.startDate).toDate() : ''}
              maxDate={item.endDate ? moment(item.endDate).toDate() : null}
            />
            <Input
              time
              id='startHour'
              label='Ora inizio'
              style={{ width: '100%', marginTop: 2, marginRight: 5 }}
              onChange={(e) => updateItem({ startHour: e.startHour !== null ? moment(e.startHour).format('HH:mm') : null })} value={
                item.startHour
                  ? item.startHour.length > 5
                      ? moment(item.startHour).toDate()
                      : moment(item.startHour, 'HH:mm').toDate()
                  : ''
              }
            />

            <Input
              date
              id='endDate'
              label='Data fine'
              style={{ width: '100%', marginTop: 5 }}
              onChange={(e) => updateItem(e)}
              value={item.endDate ? moment(item.endDate).toDate() : ''}
              minDate={item.startDate ? moment(item.startDate).toDate() : null}
            />
          </div>
          <div style={{ marginTop: 5, display: 'flex' }}>
            <Input
              id='description'
              label='Descrizione:'
              style={{ width: '100%', marginRight: 5 }}
              onChange={(e) => updateItem(e)}
              value={item.description || ''}
            />
            <Input
              itemTemplate={iconTemplate}
              valueTemplate={iconTemplate}
              dropdown
              id='icon'
              style={{
                marginRight: 5,
                minWidth: 180,
                maxWidth: screenWidth > config.smallScreen ? 180 : '100%',
                marginTop: screenWidth > config.smallScreen ? 0 : 5
              }}
              // placeholder={<div style={{ display: 'flex', alignItems: 'center' }}><Text value='Icona:' style={{ marginRight: 10 }} color='rgb(120,120,120)' /><Icon name={item?.icon || 'ticket'} size={24} /></div>}
              value={item?.icon || 'ticket'}
              options={ICONS}
              // label='test'
              // placeholder={`${<Text value='Icona' />}${item.icon || ''}`}
              // placeholder={`${<Icon name={item.icon.props.name} /> || ''}`}
              onChange={({ icon }) => updateItem({ icon })}
            />
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center'
          }}
          >
            <Input
              id='address'
              label='Indirizzo'
              style={{ gridArea: 'address', marginTop: 5, marginRight: 2, marginLeft: 2, width: '100%' }}
              onChange={(e) => updateItem(e)}
              value={item.address || ''}
            />
            <Input
              id='num'
              label='Num.'
              style={{ gridArea: 'num', marginTop: 5, marginRight: 2, marginLeft: 2, width: '40%' }}
              onChange={(e) => updateItem(e)}
              value={item.num || ''}
            />
            <Input
              id='city'
              label='Citta'
              style={{ gridArea: 'city', marginTop: 5, marginRight: 2, marginLeft: 2, width: '100%' }}
              onChange={(e) => updateItem(e)}
              value={item.city || ''}
            />
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center'
          }}
          >
            <Input
              id='cap'
              label='Cap'
              style={{ gridArea: 'cap', marginTop: 5, marginRight: 2, marginLeft: 2, width: '100%' }}
              onChange={(e) => updateItem(e)}
              value={item.cap || ''}
            />

            <Input
              id='province'
              label='Provincia'
              style={{ gridArea: 'province', marginTop: 5, marginRight: 2, marginLeft: 2, width: '100%' }}
              onChange={(e) => updateItem(e)}
              value={item.province || ''}
            />
            <NumberPrefix
              style={{ gridArea: 'province', marginTop: 5, marginRight: 2, marginLeft: 2, width: '100%' }}
              onChangeSelectedCountry={(update) => changeSelectedCountry(update)}
              prefixCountry={prefixCountry}
            />
            <Input
              id='telephone'
              label='Telefono'
              style={{ gridArea: 'telephone', marginTop: 5, marginRight: 2, marginLeft: 2, width: '100%' }}
              onChange={(e) => updateItem(e)}
              value={item.telephone || ''}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          marginLeft: 15
        }}
      >
        <Button
          rich
          icon='bin'
          round
          style={{ margin: 3 }}
          width={30}
          onClick={() => onDelete('serviceForm', item._id)}
        />
      </div>
    </>
  )

  return (
    <div>
      <div>
        {index > 0 && (
          <hr
            style={{
              height: 2,
              width: '100%',
              backgroundColor: 'white',
              border: 'none'
            }}
          />
        )}
      </div>
      {index > 0
        ? (
          <FadeIn style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            {renderContent()}
          </FadeIn>)
        : (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            {renderContent()}
          </div>)}
    </div>
  )
}

const styles = {
  main: {
    width: '100%',
    height: 'max-content',
    display: 'flex',
    flexDirection: 'column'
  },
  ever: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}
