import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment'

import { Button, Text, Flex } from '@Common/Components'
import { showGrowl } from '../../Functions'
import Table from './Table'

export default function TabTours ({ tours: propTours, atl }) {
  const [tours, setTours] = useState(propTours)
  const [tourSelected, setTourSelected] = useState([])
  const showDeleteAll = useRef(null)

  const getOperatorLastModified = (logs) => {
    if (!logs) return 'Nessuna ultima modifica'
    if (Array?.isArray(logs) && logs?.length) return logs[0]?.operator || ''
    return logs?.operator || logs?.user || ''
  }

  const getStatus = (status = 0) => {
    if (!Number.isInteger(status)) return status
    return status === 1 ? 'Sincronizzata' : 'Non sincronizzata'
  }

  useEffect(() => {
    const correctList = propTours.map((tour) => ({
      ...tour,
      status: getStatus(tour?.status),
      operator: getOperatorLastModified(tour?.logs)
    }))
    setTours(correctList)
    sortDate({ data: correctList, order: -1 })
  }, [propTours])

  const sortDate = (event) => {
    if (!tours) return []
    const data = event?.data || tours
    const order = event?.order || -1
    const sorted = data.sort((e1, e2) => {
      const sum = moment(e1.creationDate).unix() - moment(e2.creationDate).unix()
      return sum * order
    })
    return sorted
  }

  const onClickDeleteSelected = () => {
    if (tourSelected.length > 0) showDeleteAll?.current?.show()
    else showGrowl('error', 'Selezionare almeno un viaggio da eliminare', 'Selezionane una dalla tabella')
  }

  const buttonProps = (rich = false, round = false, icon, tooltip = false, style, label = false) => {
    return {
      rich,
      round,
      icon,
      ...(label ? { label } : {}),
      ...(tooltip ? { tooltip } : {}),
      style
    }
  }

  return (
    <>
      <Flex row as fw je height={35} style={{ position: 'relative', marginTop: 10, paddingRight: 2, marginBottom: 40 }}>
        <Button
          {...buttonProps(true, false, 'bin', false, { marginRight: 16 }, 'Elimina selezionati')}
          onClick={onClickDeleteSelected}
        />
      </Flex>
      <Table
        tours={tours}
        tourSelected={tourSelected}
        setTourSelected={setTourSelected}
        sortDate={sortDate}
        showDeleteAll={showDeleteAll}
        buttonProps={buttonProps}
      />
      <Flex js>
        <Text value={`Hai ${tours.length} viagg${tours.length === 1 ? 'io' : 'i'}`} />
        {tourSelected.length > 0 && (
          <Text
            value={` selezionat${tourSelected.length === 1 ? 'o' : 'i'} ${tourSelected.length} viagg${tourSelected.length === 1 ? 'io' : 'i'} `}
          />
        )}
      </Flex>
    </>
  )
}
